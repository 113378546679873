* {
  font-family: 'Roboto Flex';
}

.code {
  height: 100%;
  width: 37%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 40px;

  @media (max-width: 1000px) {
    width: 100%;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 21px;
    max-width: 237px;
    text-align: center;
  }

  &__button {
    padding: 12px 40px;
    background-color: #000;
    color: #fff;
    font-weight: 700;
    font-size: 17px;
  }

  &__no-code {
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }
}
