.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;

    &__arrows {
      &--active {
        color: #000;
        cursor: pointer;
        transition: all 0.2s ease-in-out !important;

        &:hover {
          transform: scale(1.2);
        }
      }

      &--inactive {
        color: #ccc;
      }
    }

    &__pages {
      display: flex;
      flex-direction: row;
      gap: 0px;
      overflow: scroll;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        display: none;
      }

      &__page {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        scroll-snap-align: center;

        &__item {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          height: 100%;
          max-width: 50%;

          &:hover &__image {
            transform: scale(0.95);
          }

          &__image {
            pointer-events: none;
            background-size: cover;
            background-position: center;
            border-radius: 5px;
            transition: all 0.2s ease-in-out;
          }

          &__info {
            &__title {
              margin-top: 5px;
              font-size: 12px;
              font-weight: 700;
              color: #000;
              text-align: center;
            }

            p {
              font-weight: 600;
              color: #000;
              text-align: center;
            }
          }
        }
      }
    }
  }

  &__stepper {
    font-weight: 500;
    color: gray;
  }

  @media only screen and (min-width: 1000px) {
    $carousel-width: 800px;

    width: $carousel-width;

    &__container {
      &__arrows {
        width: 30px !important;
        height: 30px !important;
      }

      &__pages {
        width: $carousel-width - 100px;

        &__page {
          min-width: $carousel-width - 100px;
          gap: 30px;

          &__item {
            &__image {
              height: 250px;
              min-width: 170px;
            }

            &__info {
              h3 {
                font-size: 18px;
              }

              p {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    &__stepper {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 999px) {
    $carousel-width: 365px;

    width: $carousel-width;

    &__container {
      &__arrows {
        width: 20px !important;
        height: 20px !important;
      }

      &__pages {
        width: calc($carousel-width - 60px);

        &__page {
          min-width: calc($carousel-width - 60px);
          gap: 15px;

          &__item {
            &__image {
              height: 200px;
              min-width: 130px;
            }

            &__info {
              h3 {
                font-size: 16px;
              }

              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    &__stepper {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 374px) {
    $carousel-width: 310px;

    width: $carousel-width;

    &__container {
      &__arrows {
        width: 20px !important;
        height: 20px !important;
      }

      &__pages {
        width: calc($carousel-width - 60px);

        &__page {
          min-width: calc($carousel-width - 60px);
          gap: 10px;

          &__item {
            &__image {
              height: 175px;
              min-width: 115px;
            }

            &__info {
              h3 {
                font-size: 14px;
              }

              p {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    &__stepper {
      font-size: 12px;
    }
  }
}

.carousel-stepper {
  display: flex;
  flex-direction: row;
  gap: 10px;

  &__step {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out;

    &--active {
      background-color: black;
    }

    &--inactive {
      cursor: pointer;
      background-color: #d9d9d9;

      &:hover {
        background-color: #bfbfbf;
      }
    }
  }
}
