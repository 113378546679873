* {
  font-family: 'Roboto Flex';
}

.info {
  width: 364px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 50px 28px 0px 28px;
  transition: transform 1.5s ease;

  @media (max-width: 1000px) {
    width: 100%;
  }

  &__container {
    transition: opacity 1.5s ease;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    max-width: 308px;
    line-height: 24px;

    z-index: 2;
    position: relative;

    @media (max-width: 1000px) {
      margin-top: 30px;
    }
  }

  &__inputs {
    margin-top: 10px;
  }
}
