@import '../../../../../index.css';

.weight_desktop {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 20px 0 0 0;
  gap: 50px;

  &__description {
    color: black;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  &__help {
    width: 25px;
    height: auto;
    cursor: help;
  }
}
