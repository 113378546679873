.continue-button {
  width: 100%;
  padding: 12px 10px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto Flex';
  background-color: #000;
  color: #fff;

  &__arrow {
    transform: rotate(180deg) translateX(-40px);
  }

  &:disabled {
    background-color: rgba(224, 224, 224, 0.8);
  }
}
