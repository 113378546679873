@import '../../../../index.css';

.questions-mobile {
  display: flex;
  width: 100%;
  height: 100%;

  &__block {
    width: 100%;
  }

  &__container {
    width: 100%;
    height: 100%;
    transition: 0.35s;

    &__content {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      height: 100%;
      transition: 0.5s;
      padding: 56px 28px 0px 28px;

      &__title {
        white-space: pre-line;
        font-weight: 800;
        line-height: 27px;
        letter-spacing: -0.5px;
        font-size: 20px;
        margin-bottom: 5px;
      }

      &__question {
        width: 80%;
        min-height: 75px;

        &__title {
          color: #5e5e5e;
          width: 100%;
          height: 35%;
          padding: 3px 0 3px 0;
        }

        &__input {
          width: 100%;
          display: flex;
          flex-direction: row;
          position: relative;
          justify-content: center;
        }

        &__error {
          color: red;
          font-size: 12px;
        }
      }

      &__button {
        font-size: 14px;
        font-weight: 800;
        text-transform: none;
        background-color: black;
        height: 50px;
        width: 250px;
        border-radius: 5px;
        color: white;
        margin-top: 15px;

        @media (max-width: 1000px) {
          width: 100%;
        }

        &:hover {
          background-color: rgb(0, 0, 0, 0.8);
        }

        &:disabled {
          color: white;
          background-color: rgb(224, 224, 224, 0.8);
        }
      }
    }
  }
}
