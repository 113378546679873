@import '../../../../index.css';

.intro-desktop {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  width: 364px;
  height: 100%;
  background-color: rgb(255, 255, 255, 0.8);
  padding: 53px 19px 0px 19px;

  @media (max-width: 1000px) {
    width: 100%;
    background-color: #fff;
  }

  &__block {
    width: 80%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;

    @media (max-width: 1000px) {
      color: #000;
    }

    &__title {
      white-space: wrap;
      font-weight: 600;
      letter-spacing: -0.5px;
      text-align: center;
      line-height: 24px;
      font-size: 20px;
      max-width: 308px;
    }

    &__description {
      white-space: pre-line;
      font-weight: 400;
      text-align: center;
      padding: 0;
      font-size: 14px;
      line-height: 19px;
      margin-top: 66px;
    }

    &__buttons {
      align-self: center;
      display: flex;
      flex-direction: row;
      column-gap: 26px;
      margin-top: 66px;

      @media (max-width: 1000px) {
        flex-direction: column;
        row-gap: 23px;
      }

      &__button {
        font-weight: 500;
        text-transform: none;
        height: 43px;
        background-color: rgba(255, 255, 255, 0.7);
        color: black;
        border: 1px solid #0c0c0c;
        font-size: 16px;
        width: 150px;

        &:hover {
          border: 0;
          color: white;
          background-color: black;
        }

        &--active {
          background-color: black;
          color: white;
          border: 0;
        }
      }

      &__continue {
        font-size: 14px;
        font-weight: 700;
        text-transform: none;
        background-color: black;
        height: 55px;
        width: 300px;
        border-radius: 5px;
        color: white;
        margin-top: 27px;

        &:hover {
          background-color: rgb(0, 0, 0, 0.8);
        }

        &:disabled {
          color: white;
          background-color: rgb(224, 224, 224, 0.8);
        }
      }
    }
  }
}
