.textfield {
  max-height: 75px;

  &__title {
    font-size: 10px;
    color: #5e5e5e;
    width: 100%;
    height: 35%;
    padding: 3px 0 3px 0;
  }

  &__input {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
  }

  &__error {
    color: red;
    font-size: 12px;
  }
}
