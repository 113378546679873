@import '../../../../index.css';

.questions-desktop {
  display: flex;
  width: 364px;
  height: 100%;

  &__container {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    transition: transform 1.5s ease;
    transform: translateX(0%);

    &__content {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      height: 100%;
      transition: 0.5s;
      padding: 56px 28px 0px 28px;

      &__title {
        white-space: pre-line;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: -0.5px;
        font-size: 20px;
        margin-bottom: 25px;
      }

      &__question {
        min-height: 75px;

        &__title {
          color: #5e5e5e;
          width: 100%;
          height: 35%;
          padding: 3px 0 3px 0;
        }

        &__input {
          width: 100%;
          display: flex;
          flex-direction: row;
          position: relative;
          justify-content: center;
        }

        &__error {
          color: red;
          font-size: 12px;
        }
      }

      &__button {
        font-size: 14px;
        font-weight: 800;
        text-transform: none;
        background-color: black;
        height: 55px;
        width: 300px;
        border-radius: 5px;
        color: white;
        margin-top: 27px;

        &:hover {
          background-color: rgb(0, 0, 0, 0.8);
        }

        &:disabled {
          color: white;
          background-color: rgb(224, 224, 224, 0.8);
        }
      }

      &__copyright {
        height: fit-content;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 27px;

        p {
          color: rgba(0, 0, 0, 0.7);
          font-size: 10px;
          font-weight: 300;
          text-align: center;
          margin-bottom: 3px;
          cursor: default;
        }

        img {
          width: 40px;
          height: auto;
        }
      }
    }
  }
}
