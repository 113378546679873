.privacy-policy {
  display: flex;
  justify-content: center;
  column-gap: 3px;

  p {
    white-space: nowrap;
    font-size: 10px;
    font-weight: 300;
    font-family: 'Roboto Flex';

    &:last-child {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
