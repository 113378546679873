@import '../../../index.css';

.result_error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    font-weight: 800;
    text-align: center;
    white-space: pre-line;
  }

  &__icon {
    font-size: 150px !important;
  }

  &__description {
    font-weight: 700;
    text-align: center;
    white-space: pre-line;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__refresh {
      font-weight: 800;
      text-transform: none;
      background-color: black;
      border-radius: 5px;
      color: white;

      &:hover {
        background-color: rgb(0, 0, 0, 0.8);
      }
    }

    &__quit {
      border: 2px solid #000;
      font-weight: 800;
      text-transform: none;
      background-color: transparent;
      border-radius: 5px;
      color: black;

      &:hover {
        background-color: black;
        color: white;
      }
    }

    &__survey {
      font-weight: 800;
      text-transform: none;
      background-color: white;
      border-radius: 5px;
      color: black;
      border: 1px solid #000;

      &:hover {
        background-color: rgb(0, 0, 0, 0.05);
      }
    }
  }

  @media only screen and (min-width: 1000px) {
    &__title {
      font-size: 32px;
    }

    &__description {
      font-size: 20px;
      margin-bottom: 40px;
      width: 450px;
    }

    &__buttons {
      &__refresh {
        font-size: 14px;
        width: 350px;
        height: 50px;
      }

      &__quit {
        font-size: 14px;
        width: 350px;
        height: 50px;
        margin-bottom: 15px;
      }

      &__survey {
        font-size: 14px;
        width: 350px;
        height: 50px;
      }
    }
  }

  @media only screen and (max-width: 999px) {
    width: 100%;
    margin: 60px 0 40px 0;
    padding: 0 10px;

    &__title {
      font-size: 26px;
    }

    &__description {
      font-size: 16px;
      margin-bottom: 40px;
    }

    &__buttons {
      width: 350px;

      &__refresh {
        font-size: 12px;
        width: 100%;
        height: 50px;
      }

      &__quit {
        font-size: 12px;
        width: 100%;
        height: 50px;
      }

      &__survey {
        font-size: 12px;
        width: 100%;
        height: 50px;
      }
    }
  }

  @media only screen and (max-width: 374px) {
    width: 100%;
    margin: 60px 0 40px 0;

    &__title {
      font-size: 20px;
    }

    &__description {
      font-size: 13px;
      margin-bottom: 30px;
      width: 260px;
    }

    &__buttons {
      width: 300px;

      &__refresh {
        font-size: 10px;
        width: 100%;
        height: 45px;
      }

      &__quit {
        font-size: 10px;
        width: 100%;
        height: 45px;
      }

      &__survey {
        font-size: 10px;
        width: 100%;
        height: 45px;
      }
    }
  }
}
